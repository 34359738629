<template>
  <div class="maint-workOrder-query">
    <div class="vm-separate title">
      <div>
        {{$t("workOrder.workOrder")}}：{{maintWorkOrder.no}}
      </div>
      <div>
        {{$t("workOrder.workOrderStatus")}}：
        <template v-if="maintWorkOrder.status === 1">{{$t("maintWorkOrder.works")}}</template>
        <template v-else-if="maintWorkOrder.status === 2">{{$t("maintWorkOrder.execution")}}</template>
        <template v-else-if="maintWorkOrder.status === 3">{{$t("maintWorkOrder.stocks")}}</template>
        <template v-else-if="maintWorkOrder.status === 4">{{$t("maintWorkOrder.confirmed")}}</template>
      </div>
    </div>
    <div class="baseInfoWrapper">
      <div class="baseInfoTitle">
        {{$t("route.baseInfo")}}
      </div>
      <div class="baseInfoContent">
        <div class="item">
          <div class="itemTitle">{{$t("workOrder.information")}}</div>
          <div class="vm-separate itemContent">
            <div>{{$t("maintWorkOrder.dateList.plan")}}：{{maintWorkOrder.maintDate}}</div>
            <div>{{$t("maintWorkOrder.dateList.start")}}：{{maintWorkOrder.startTime}}</div>
            <div>{{$t("maintWorkOrder.dateList.completion")}}：{{maintWorkOrder.finishTime}}</div>
          </div>
          <div class="vm-separate itemContent">
            <div>{{$t("maintWorkOrder.dateList.confirmation")}}：{{maintWorkOrder.confirmTime}}</div>
            <div>
              {{$t("elevator.maintType")}}:
              <template v-if="maintWorkOrder.cycleType === 1">
                {{$t("maintWorkOrder.maintenanceWay.halfMonths")}}
              </template>
              <template v-else-if="maintWorkOrder.cycleType === 2">
                {{$t("maintWorkOrder.maintenanceWay.quarter")}}
              </template>
              <template v-else-if="maintWorkOrder.cycleType === 3">
                {{$t("maintWorkOrder.maintenanceWay.halfYear")}}
              </template>
              <template v-else-if="maintWorkOrder.cycleType === 4">
                {{$t("maintWorkOrder.maintenanceWay.year")}}
              </template>
            </div>
            <div></div>
          </div>
        </div>
        <div class="item">
          <div class="itemTitle">{{$t("workOrder.propertyInfo")}}</div>
          <div class="vm-separate itemContent">
            <div>{{$t("workOrder.propertyName")}}：{{maintWorkOrder.propertyComName}}</div>
            <div>{{$t("workOrder.contactPerson")}}：{{maintWorkOrder.propertyComPerson}}</div>
            <div>{{$t("workOrder.contactTel")}}：{{maintWorkOrder.propertyComPhone}}</div>
          </div>
        </div>
        <div class="item">
          <div class="itemTitle">{{$t("elevator.maintenanced")}}</div>
          <div class="vm-separate itemContent">
            <div>{{$t("maintWorkOrder.company")}}：{{maintWorkOrder.maintCom}}</div>
            <div>{{$t("workOrder.servicePersonnel")}}：{{maintWorkOrder.maintainer}}</div>
          </div>
        </div>
        <div class="item">
          <div class="itemTitle">{{$t("workOrder.elevatorInfo")}}</div>
          <div class="vm-separate itemContent">
            <span>{{$t("elevator.no")}}：{{maintWorkOrder.elevatorNo}}</span>
            <span>{{$t("elevator.name")}}：{{maintWorkOrder.elevatorName}}</span>
          </div>
          <div class="vm-separate itemContent">
            <span>{{$t("elevator.elevatorType")}}：{{maintWorkOrder.elevatorProductType}}</span>
            <span>{{$t("elevator.useUnit")}}：{{maintWorkOrder.useUnit}}</span>
          </div>
        </div>
        <div class="item">
          <div class="itemTitle">{{$t("maintWorkOrder.content")}}</div>
          <div class="itemContent">
            <el-tabs value="tab0">
              <el-tab-pane
                v-for="(item,index) in maintWorkOrder.contents"
                :key="index"
                :label="getContentType(item.classify)"
                :name="'tab'+index">
                <div v-for="(childItem,index) in item.contents" :key="index" class="maintContentItem">
                  <div class="maintContentItem1">{{childItem.content}}</div>
                  <div class="maintContentItem2">{{childItem.requirement}}</div>
                  <div class="maintContentItemResult">{{getMaintItemResult(childItem.result)}}</div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="item">
          <div class="itemTitle">现场图片</div>
          <div style="display: flex;flex-wrap:wrap">
            <el-image
              v-for="(item,index) in maintImageUrls"
              :key="index"
              :src="item"
              :preview-src-list="[item]"
              style="width: 400px;height: 400px;margin: 5px"
              fit="contain"></el-image>
          </div>
        </div>
        <div class="item">
          <div class="itemTitle">{{$t("maintWorkOrder.userEvaluation")}}</div>
          <div class="vm-separate">
            <span>
              {{$t("maintWorkOrder.property")}}：
              <img v-if="propertySignUrl" :src="propertySignUrl" style="width: 100%"/>
              <span v-else>无</span>
            </span>
            <span>
              {{$t("maintWorkOrder.maintenance")}}：
              <img v-if="maintainerSignUrl" :src="maintainerSignUrl" style="width: 100%"/>
              <span v-else>无</span>
            </span>
            <span>{{$t("maintWorkOrder.satisfaction")}}：{{maintWorkOrder.satisfaction}}</span>
            <span>{{$t("maintWorkOrder.advice")}}：{{maintWorkOrder.advice}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        propertySignUrl: null,
        maintainerSignUrl: null,
        maintImageUrls: [],
        maintWorkOrder: {
          id: "",
          status: "",
          maintDate: "",
          startTime: "",
          finishTime: "",
          cycleType: "",
          maintCom: "",
          maintainer: "",
          propertyComName: "",
          propertyComPerson: "",
          propertyComPhone: "",
          elevatorNo: "",
          elevatorName: "",
          elevatorProductType: "",
          product: "",
          useUnit: "",
          propertySign: "",
          maintainerSign: "",
        },
      };
    },
    mounted() {
      this.maintWorkOrder.id = this.$route.params.maintWorkOrderId;
      this.getData();
    },
    methods: {
      getData() {
        this.$api.getById("maintWorkOrder", this.maintWorkOrder.id).then(res => {
          console.log(res.data);
          this.maintWorkOrder = res.data;
          let images = this.maintWorkOrder.files;
          for (let item of images) {
            // 1现场图片,2维保工签名,3物业签名
            let url = window.config.SERVER_URL + item.path;
            switch (item.relatedType) {
              case 1:
                this.maintImageUrls.push(url);
                break;
              case 2:
                this.maintainerSignUrl = url;
                break;
              case 3:
                this.propertySignUrl = url;
                break;
            }
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
          }
        });
      },
      getContentType(val) {
        switch (val) {
          case 1:
            return "机房";
          case 2:
            return "桥顶";
          case 3:
            return "桥厢";
          case 4:
            return "层门";
          case 5:
            return "底坑井道";
          default:
            return "其他";
        }
      },
      getMaintItemResult(val) {
        switch (val) {
          case 0:
            return "无需保养";
          case 1:
            return "已经保养";
          case 2:
            return "需要更换";
        }
        return "";
      },
    },
  };
</script>
<style lang="scss" scoped>
.maint-workOrder-query {
  width: 900px;
  margin: 0 auto;
  padding: 30px 60px;
  color: #555;

  .title {
    border: solid 1px #D63B3F;
    background-color: #FBEBEB;
    color: #444;
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .baseInfoWrapper {
    border: solid 1px #DCDFE6;

    .baseInfoTitle {
      background-color: #E4F0F4;
      border-bottom: solid 1px #DCDFE6;
      font-weight: 600;
      font-size: 16px;
      padding: 15px
    }

    .baseInfoContent {
      padding: 10px 30px;

      .item {
        padding: 5px 5px;
        border-bottom: solid 1px #DCDFE6;

        .itemTitle {
          font-weight: 600;
          color: #2B80DA;
          margin-bottom: 10px;
          margin-top: 10px;
        }

        .itemContent {
          font-size: 15px;
          margin: 5px 0;
        }
      }
    }
  }

  .maintContentItem {
    border-bottom: 1px dashed #ddd;
    padding: 8px 10px;

    .maintContentItem1 {
      color: #444;
    }

    .maintContentItem2 {
      margin-top: 8px;
      color: #888;
      font-size: 14px;
    }

    .maintContentItemResult {
      margin-top: -30px;
      float: right;
    }
  }
}
</style>
